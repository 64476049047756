<template>
  <div id="app">
    <div class="ambiente">{{ ambiente }}</div>
    <router-view/>
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  data() {
    return {
      ambiente: "Indefinido",
    };
  },
  mounted() {
    this.ambiente = process.env.VUE_APP_AMBIENTE || "Indefinido";
  }
};
</script>
<style>
.ambiente {
  position: fixed;
  top: 0;
  right: 0;
  background: #999;
  padding: 5px 8px 5px 10px;
  z-index: 999999;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0 0 0 10px;
}
</style>