
export default {
    path: "/taxonomies-types",
    name: "TaxonomiesTypes",
    meta: {
        authRequired: true,
    },
    component: () => import("./taxonomies-types.vue")
}

