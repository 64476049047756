
export default {
    path: "/posts-types",
    name: "PostsTypes",
    meta: {
        authRequired: true,
    },
    component: () => import("./posts-types.vue")
}

