
export default {
    path: "/core-forms",
    name: "CoreForms",
    meta: {
        authRequired: true,
    },
    component: () => import("./core-forms.vue")
}

